import React from 'react';

import SEO from '../components/seo';
import { Layout } from '../layout/Layout';
import { BackgroundBase } from '../backgrounds/BackgroundBase';
import { Container as BaseContainer } from '../components/Container';
import { H3, SuperHeader } from '@increasecard/typed-components';
import { RoundedSquare } from '../backgrounds/shapes/RoundedSquare';
import styled from 'styled-components';
import { CTAButton } from '../components/CTAButton';
import { MEDIUM } from '../breakpoints';
import { graphql } from 'gatsby';

const Container = styled(BaseContainer)`
  color: ${({ theme }) => theme.colorsNew.gray.white};
  text-align: center;
  max-width: 640px;
  min-height: calc(100vh - 80px);
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: repeat(3, max-content);
  grid-row-gap: 32px;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  padding-top: 64px;
  padding-bottom: 64px;
  @media (min-width: ${MEDIUM}) {
    padding-top: 64px;
    padding-bottom: 0;
  }
`;

const Title = styled(SuperHeader)``;

const Text = styled(H3).attrs({ weight: 'normal' })``;

const CTAGroup = styled.div`
  display: grid;
  justify-content: center;
  grid-gap: 16px;
  grid-template-columns: 100%;
  width: 100%;

  @media (min-width: ${MEDIUM}) {
    grid-template-columns: max-content max-content;
  }
`;

const FourZeroFour = styled.div`
  color: ${({ theme }) => theme.colorsNew.increase.accent};
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0.15;
  font-weight: bold;
  font-size: 200px;
  line-height: 220px;
  top: calc(50% - 100px - 64px);

  @media (min-width: ${MEDIUM}) {
    font-size: 455px;
    line-height: 500px;
    top: calc(50% - 250px - 64px);
  }
`;

const BigSquare = styled(RoundedSquare).attrs({
  bgColor: theme => theme.colorsNew.increase.light50,
  size: { sm: '80px', md: '240px' },
  rotation: '21deg',
})`
  position: absolute;
  left: calc(-80px / 2);
  bottom: calc(-80px / 2);
  @media (min-width: ${MEDIUM}) {
    left: calc(-240px / 2);
    bottom: calc(-240px / 2 + 80px);
  }
`;
const OutlineSquare = styled(RoundedSquare).attrs({
  size: { sm: '120px', md: '312px' },
  rotation: '-30deg',
})`
  border: 2px solid ${({ theme }) => theme.colorsNew.gray.white};
  position: absolute;
  right: calc(-120px / 2);
  bottom: calc(5% - 120px / 2);
  @media (min-width: ${MEDIUM}) {
    right: calc(-312px / 2 - 64px);
    top: calc(50% - 312px / 2);
  }
`;
const SmallSquare = styled(RoundedSquare).attrs({
  bgColor: theme => theme.colorsNew.increase.light50,
  size: '24px',
  rotation: '21deg',
})`
  position: absolute;
  top: 10%;
  right: 10%;
`;

function NotFoundPage({ data }) {
  const {
    title,
    description,
    primaryCTA,
    secondaryCTA,
    seoInfo,
  } = data.strapi.fourZeroFourPage;
  return (
    <Layout variant="increase-dark">
      <SEO title={seoInfo.pageTitle} description={seoInfo.pageDescription} />
      <BackgroundBase
        baseBackground={theme => theme.colorsNew.increase.dark}
        renderBackground={() => (
          <>
            <FourZeroFour>404</FourZeroFour>
            <BigSquare />
            <OutlineSquare />
            <SmallSquare />
          </>
        )}
      >
        <Container>
          <Title dangerouslySetInnerHTML={{ __html: title }} />
          <Text dangerouslySetInnerHTML={{ __html: description }} />
          <CTAGroup>
            <CTAButton {...primaryCTA} type="accent" />
            <CTAButton {...secondaryCTA} type="accent-outline" />
          </CTAGroup>
        </Container>
      </BackgroundBase>
    </Layout>
  );
}

export default NotFoundPage;

export const query = graphql`
  query {
    strapi {
      fourZeroFourPage {
        seoInfo {
          pageTitle
          pageDescription
        }
        title
        description
        primaryCTA {
          ...CTA
        }
        secondaryCTA {
          ...CTA
        }
      }
    }
  }
`;
